@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&display=swap");
/* @import url('./fonts/Dubai-Regular.ttf'); */
/* @import url('../DubaiRegular/Dubai-Regular.ttf'); */

@import url("https://cdn.rawgit.com/rtaibah/dubai-font-cdn/master/dubai-font.css");

/* font-family: 'Inter', sans-serif; */

/* ===========Arabic Design===================== */

.arabic-lang {
  direction: rtl;
  /* font-family: 'Inter', sans-serif; */
  font-family: "Dubai-Regular", sans-serif;
  /* font-family: 'Dubai-Regular', Helvetica, sans-serif; */
  /* font-weight: normal; */
  font-style: normal;
}
.arabic-lang .search-hotel .tab-container {
  border-radius: 25px 0px 25px 25px;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .nav-tabs .nav-link {
  margin-left: 6px;
  margin-right: 0;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .form-content button {
  border-radius: 25px 0px 0px 25px;
  font-family: "Dubai-Regular";
}
.arabic-lang .check-in .MuiInputBase-root button {
  background-position: 0 -1px;
  font-family: "Dubai-Regular";
}
.arabic-lang .check-out .MuiInputBase-root button {
  background-position: 8px 8px;
  font-family: "Dubai-Regular";
}
.arabic-lang .check-out .MuiInputAdornment-root {
  margin-left: -8px;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .form-content input.location {
  background-position: 5% 20px;
  border-left: 1px solid #ddd;
  border-right: none;
  padding-left: 40px;
  padding-right: 10px;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .form-content input.check-in {
  background-position: 6% 8px;
  padding-right: 20px;
  padding-left: 0;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .form-content input.check-out {
  border-left: 1px solid #ddd;
  border-right: none;
  background-position: 6% 20px;
  font-family: "Dubai-Regular";
}
.arabic-lang .datepickercheck-in .MuiFormLabel-root {
  left: auto;
  right: 10px;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .tab-container.hourly-stay .form-box.checkin {
  border-left: 1px solid #ddd;
  border-right: none;
  font-family: "Dubai-Regular";
}
.arabic-lang .search-hotel .tab-container.hourly-stay .form-box.checkin input {
  direction: ltr;
  text-align: right;
}
.arabic-lang .guestpop {
  left: auto;
  right: 16px;
}
.arabic-lang .inner-banner .search-hotel .tab-container .right {
  padding-left: 20px;
  padding-right: 0;
  border-radius: 15px;
}
.arabic-lang .carousel_st .slick-prev,
.arabic-lang .carousel_st .slick-prev:hover,
.arabic-lang .carousel_st .slick-prev:focus,
.arabic-lang .customer-review .slick-prev,
.arabic-lang .customer-review .slick-prev:hover,
.arabic-lang .customer-review .slick-prev:focus {
  left: 14px;
  right: auto;
}
.arabic-lang .carousel_st .slick-next,
.arabic-lang .carousel_st .slick-next:hover,
.arabic-lang .carousel_st .slick-next:focus,
.arabic-lang .customer-review .slick-next,
.arabic-lang .customer-review .slick-next:hover,
.arabic-lang .customer-review .slick-next:focus {
  left: 60px;
  right: auto;
}
.arabic-lang .inner_offerbox {
  flex-direction: row-reverse;
}
.arabic-lang .inner_offerbox h4 {
  text-align: right;
}
.arabic-lang .popular-destinations .popular-destinations-header .col-md-5 {
  text-align: left;
}
.arabic-lang .popular-destinations .caption {
  left: auto;
  right: 30px;
}
.arabic-lang .app-wrapper .app-txt {
  padding-right: 83.32px;
  padding-left: 0;
}
.arabic-lang .app-wrapper p {
  padding-left: 30%;
  padding-right: 0;
}
.arabic-lang .app-wrapper ul.app-icon li {
  padding-left: 38.28px;
  padding-right: 0;
}
.arabic-lang .reviewcontent {
  padding-left: 154px;
  padding-right: 0;
}

.arabic-lang .footerpadding {
  padding-right: 120px;
  padding-left: 0;
}
.arabic-lang .copyright {
  text-align: left;
}
.arabic-lang .listing-header .header-right .map {
  margin-right: auto;
  margin-left: 0;
}
.arabic-lang .listing-wrapper .sort-by .btn {
  text-align: right;
}
.arabic-lang .listing-wrapper .sort-by .btn img {
  float: left;
}
.arabic-lang .listing-wrapper .sort-by .dropdown-menu .form-check {
  text-align: right;
}
.arabic-lang .listing-box .right {
  padding-right: 24px;
  padding-left: 0;
}
.arabic-lang .listing-box .address {
  padding: 0 25px 10px 0;
}
.arabic-lang .listing-box .address .icon {
  left: auto;
  right: 0;
}
.arabic-lang .hotel-title {
  padding-left: 20px;
  padding-right: 0;
}
.arabic-lang .hotel-title .star-rating,
.arabic-lang .listing-details-header .title .star-rating {
  padding-right: 10px;
  padding-left: 0;
  display: flex;
  align-items: flex-start;
  height: 18px;
}
.star-rating {
  display: flex;
  align-items: flex-start;
  height: 18px;
}
.arabic-lang .listing-box .top-bar .rating-txt {
  margin-left: 0;
  margin-right: 10px;
}
.arabic-lang .listing-box .amenities .amenities-item ul li,
.arabic-lang .hotel-listing-box .amenities-item ul li {
  padding: 0 25px 10px 5px;
}
.arabic-lang .listing-box .amenities .amenities-item ul li .icon,
.arabic-lang .hotel-listing-box .amenities-item ul li .icon {
  left: auto;
  right: 0;
}
.arabic-lang .listing-box .amenities .price-content ul li span.hrs,
.arabic-lang .hotel-listing-box .price-content ul li span.hrs {
  border-right: none;
  border-left: 1px solid #fff;
}
.arabic-lang .listing-box .slide-box .slide-item .favourite,
.arabic-lang .favourite {
  left: auto;
  /*right: 18px;*/
}
.arabic-lang .list-pagination .right {
  text-align: left;
}
.arabic-lang .inner-banner .search-hotel .tab-container .right button {
  margin-left: 30px;
  margin-right: auto;
  transform: rotate(90deg);
  -webkit-transform: rotate(90deg);
}
.arabic-lang .accordion-button::after {
  margin-right: auto;
  margin-left: 0;
}
.arabic-lang .customaccordian .accordion-item .accordion-body {
  padding: 0 35px 17px 99px;
}
.arabic-lang .MuiCalendarOrClockPicker-root .MuiIconButton-edgeEnd,
.arabic-lang .MuiCalendarOrClockPicker-root .MuiIconButton-edgeStart {
  transform: rotate(180deg);
  -webkit-transform: rotate(180deg);
}
.arabic-lang footer ul.footlocation li img {
  margin-right: 0px;
  margin-left: 20px;
}
.arabic-lang .footer-socialarea a {
  margin-right: 0px;
  margin-left: 30px;
}
.arabic-lang .listing-details-header .title {
  padding-left: 20px;
  padding-right: 0;
}
.arabic-lang .listing-details-header .icon-list {
  padding-left: 40px;
  padding-right: 0;
}
.arabic-lang .listing-details-header .rating-txt {
  margin-right: 10px;
  margin-left: 0;
}
.arabic-lang .listing-details-header .address {
  padding: 0 25px 0 0;
}
.arabic-lang .listing-details-header .address .icon {
  left: auto;
  right: 0;
}
.arabic-lang .popup-slider {
  padding-right: 20.52px;
  padding-left: 0;
}
.arabic-lang .listing-box .slide-box .slide-item {
  padding: 0 2px;
}
.arabic-lang .nearby-location-map .view-map {
  right: auto;
  left: 10px;
}
.arabic-lang .hotel-listing-box .middle {
  padding-right: 24px;
  padding-left: 10px;
}
.arabic-lang .customer-rating .rating-container .reviews {
  padding-left: 0;
  padding-right: 26px;
}
.arabic-lang .customer-rating .rating-container .rating-box {
  margin-left: 10px;
  margin-right: 0;
}
.arabic-lang .passwordsec svg {
  right: auto;
  left: 16px;
}
.arabic-lang .form-checkbox .form-check {
  float: right;
}
.arabic-lang .form-checkbox .form-check input {
  margin-left: 11px;
  float: right;
  margin-right: 0;
  width: 21px;
}
.arabic-lang .WL_contactmwrp .form-select {
  padding: 0 25px 0 35px;
  background-position: 15px 50%;
}
.arabic-lang .hourlable {
  left: auto;
  right: 120px;
  padding-right: 12px;
  padding-left: 0;
  border-right: 1px solid #ddd;
  border-left: none;
  margin-right: 10px;
}
.arabic-lang .price-range .multi-range-slider {
  direction: ltr;
}
.arabic-lang .inner-banner .check-in .MuiInputBase-root button,
.arabic-lang .inner-banner .check-out .MuiInputBase-root button {
  background-position: 15px 15px;
}
.arabic-lang .check-out {
  border-left: 1px solid #ddd;
  border-right: none;
}
.arabic-lang .search-hotel .check-out input,
.arabic-lang .search-hotel .check-in input {
  padding: 16.5px 14px;
  padding-left: 0;
  direction: ltr;
  text-align: right;
}
.arabic-lang .form-content .check-in label,
.arabic-lang .form-content .check-out label {
  left: auto;
  right: 23px;
}
.arabic-lang .hourlybook select {
  background-position: 0 50%;
}
.arabic-lang .customform .form-control {
  background-position: left 0.75rem center;
}
.arabic-lang .inner-banner .search-hotel .tab-container button {
  text-indent: -3000px;
}
.arabic-lang .guestpop {
  width: 440px;
}
.arabic-lang .pophours {
  width: 370px;
  left: auto;
  right: 0;
}
.arabic-lang .pophours .css-1wnsr1i {
  width: 100%;
}
.arabic-lang .errormessagesearch {
  left: auto;
  right: 32px;
}
.arabic-lang .toprightsec ul li:last-child .dropdown-menu {
  margin-right: -146px;
  margin-left: 0;
}
.arabic-lang .toprightsec .dropdown-menu:before {
  right: auto;
  left: 12px;
}
.arabic-lang .toprightsec .dropdown-menu:after {
  right: auto;
  left: 8px;
}
.arabic-lang .countryCodePhoneDiv .react-tel-input .form-control {
  padding-left: 25px !important;
  padding-right: 48px;
}
.arabic-lang .react-tel-input .selected-flag .arrow {
  right: 28px;
  left: auto;
}
.arabic-lang .react-tel-input .selected-flag .flag {
  /* right: 15px; */
  right: 62px;
}
.arabic-lang .WL_inner_review_cont {
  margin-left: 0;
  margin-right: 20px;
}
.arabic-lang .WL_checkwrp_left:nth-child(1) {
  padding-left: 15px;
  padding-right: 0;
}
.arabic-lang .WL_select_checkintime_text {
  padding: 0 0px 0 20px;
}
.arabic-lang .WL_checkouttime_text {
  padding: 0 36px 0 0px;
}
.arabic-lang .WL_innercardholder div {
  margin-left: 10px;
  margin-right: 0;
}
.arabic-lang .react-tel-input .form-control {
  padding-left: 0;
  padding-right: 48px;
}
.arabic-lang .WL-custmpayradio .form-check {
  flex-direction: row;
  padding: 0 30px 0 0;
}

@media screen and (min-width: 766px) {
  .arabic-lang .WL-custmpayradio .form-check {
    padding: 0 0 0 30px;
  }
  .WL_creditradio_mwrp .WL-custmpayradio label {
    padding-left: 0;
    /* padding-right: 12px; */
  }
}

.arabic-lang .TC-message-container {
  text-align: right;
}

.arabic-lang .WL_revw_left {
  padding-right: 0;
  padding-left: 10px;
}
.arabic-lang .accounttotalsection .nav-tabs .nav-link {
  margin-left: 60px;
  margin-right: 0;
}
.arabic-lang .accounttable table th:first-child {
  border-radius: 0 10px 0 0;
}
.arabic-lang .accounttable table td:first-child,
.arabic-lang .accounttable table th:first-child {
  border-right: none;
  border-left: 1px solid #d9d9d9;
}
.arabic-lang .accounttable table th {
  border-right: 1px solid #d9d9d9;
  border-left: none;
}
.arabic-lang .accounttable table th:last-child {
  border-radius: 10px 0 0 0;
}
.arabic-lang .popbox {
  right: auto;
  left: 0;
}
.arabic-lang .popbox h3 {
  border-radius: 16px 16px 16px;
}
.arabic-lang .popclose {
  right: auto;
  left: 13px;
}
.arabic-lang .pointboxarea {
  margin: 17px 29px 17px 18px;
  width: 100%;
}
.arabic-lang .css-wjh20t-MuiPagination-ul {
  flex-direction: row-reverse;
  justify-content: flex-end;
}
.arabic-lang .hotel-amenities ul li .icon {
  padding-left: 10px;
  padding-right: 0;
}
.arabic-lang footer ul li:hover {
  padding-left: 0;
  padding-right: 5px;
}
.arabic-lang .sharePopup .popclose {
  left: 25px !important;
}
.arabic-lang .WL_collection_left {
  padding: 0 0 0 15px;
}
.arabic-lang .toprightsec .dropdown-menu {
  margin-left: 0;
  margin-right: -100px;
}

/* .arabic-lang .offers-rightsection {
} */
.arabic-lang .offers_slider .slick-prev,
.arabic-lang .offers_slider .slick-prev:hover,
.arabic-lang .offers_slider .slick-prev:focus {
  right: auto;
  left: 14px;
}

.arabic-lang .offers_slider .slick-next,
.arabic-lang .offers_slider .slick-next:hover,
.arabic-lang .offers_slider .slick-next:focus {
  right: auto;
  left: 60px;
}
.arabic-lang footer ul li.socialic img {
  margin-right: 0;
  margin-left: 10px;
}
.arabic-lang .footerlogo {
  margin-right: -10px;
}
.arabic-lang .datepickercheck-in .MuiInputBase-root input {
  direction: ltr;
  text-align: right;
}
.arabic-lang .customer-rating .categories .progress-bar .progess {
  left: auto;
  right: 0;
}
.arabic-lang .WL_innercontacttextinner span a {
  direction: ltr;
  text-align: right;
}
.arabic-lang .toprightsec .dropdown-menu a {
  text-align: right;
  width: 100%;
  display: inline-block;
}
.arabic-lang .forscroll {
  padding-left: 10px;
}
.arabic-lang
  .search-hotel
  .tab-container
  .form-box
  .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  left: auto;
}

/* ===========Arabic Design===================== */

.arabic-lang .react-tel-input .form-control {
  padding-right: 75px !important;
  padding-left: 0 !important;
}
.arabic-lang .react-tel-input .flag-dropdown {
  right: -44px;
}
.arabic-lang .form50 .react-tel-input .selected-flag {
  width: auto !important;
}

.arabic-lang .toprightsec .dropdown-menu a {
  font-size: 18px;
}
.arabic-lang .customratepop h3 {
  text-align: right;
}
.arabic-lang .reviewlist {
  padding-left: 0;
  padding-right: 30px;
}

.arabic-lang .login-pop-button {
  float: left;
  padding: 10px 32px;
  font-weight: 700;
}

.arabic-lang .customratepop label span svg {
  margin-right: 16px;
  margin-left: 0;
}
.arabic-lang .reviewstar img {
  margin-left: 0;
  margin-right: 5px;
}

.arabic-lang .closepop {
  right: auto;
  left: 0;
}
.arabic-lang .app-wrapper .app-pic img:nth-child(1) {
  display: none;
}
.arabic-lang .app-wrapper .app-pic img:nth-child(2) {
  display: block;
}
.arabic-lang .checkout_checkbox label {
  text-align: right;
}

.arabic-lang .customratepop ul li span {
  direction: ltr;
}
.arabic-lang .walletdropdown button.buyPointBtn {
  font-size: 16px;
}
.arabic-lang .ratepoptextarae {
  margin-right: 5px;
}
.arabic-lang .customratepop ul li legend,
.arabic-lang .ratepoptextarae textarea,
.arabic-lang .reviewlist li,
.arabic-lang .customratepop button {
  font-size: 1.2rem;
}
.arabic-lang .popbox h3 span,
.arabic-lang .pointsmallbox {
  font-size: 14px;
  font-weight: 400;
}
.arabic-lang .popbox .gen-btn {
  font-weight: 500;
}
@media (max-width: 767px) {
  .arabic-lang .customratepop label span svg {
    margin-right: 6px;
  }
}

@media (max-width: 575px) {
  .arabic-lang .reviewlist {
    padding-right: 0;
  }
}

.arabic-lang .inner-banner .search-hotel .tab-container .counting button {
  text-indent: inherit;
}
.arabic-lang .add-width {
  width: 46px;
}
.arabic-lang .bookingboxmid label {
  width: 132px;
}
.arabic-lang #datetime {
  background-position: left 0.6rem center;
}
.arabic-lang .WL_checkintime_selectbox .form-select {
  background-position: left 0.4rem center;
  padding: 5px 10px 5px 42px;
}
.arabic-lang .infoModal .modal-header span {
  width: 100%;
}
.arabic-lang .gm-style-iw {
  padding-right: 12px !important;
  padding-left: 0 !important;
}
.arabic-lang .bookingboxmid span {
  direction: ltr;
  display: inline-block;
}
.bookingboxmid span.timebook {
  font-weight: normal;
}
.arabic-lang .MuiButtonBase-root,
.arabic-lang .css-1v994a0,
.arabic-lang .css-1w13o7u-MuiTypography-root {
  font-family: Dubai-Regular, sans-serif !important ;
}
.react-tel-input .country-list .search-box {
  margin-right: 6px;
}

.arabic-lang form label {
  text-align: right;
}

.arabic-lang form input {
  text-align: right;
}

.topweather {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  gap: 8px;
  float: right;
  margin-right: 20%;
}

.small-bidding-map {
  width: 223px;
  height: 56px;
  border-radius: 8px;
  overflow: hidden;
  margin-right: auto;
}

.swal2-popup.swal2-modal {
  border-radius: 25px;

  div,
  h1 {
    font-size: 1.6rem !important;
  }
}

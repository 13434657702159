@media (max-width: 1280px) {
  /* home banner start */

  .search-hotel {
    margin-top: 480px;
  }

  /* home banner end */
}

@media (max-width: 1199px) {
  /* home banner start */

  .search-hotel {
    margin-top: 360px;
  }

  /* home banner end */

  /* listing page start */
  .listing-box .amenities .amenities-item {
    width: 65%;
  }
  .hotel-details-card-right .amenities .amenities-item {
    width: 100%;
  }

  .listing-box .amenities .price-content {
    width: 35%;
  }

  .listing-box .amenities .price-content ul li {
    font-size: 14px;
  }

  /* listing page end */

  /* listing details page start */
  .listing-details-header .icon-list a {
    margin: 5px;
  }

  .hotel-listing-box .price-content ul li {
    font-size: 12px;
  }

  .time-slot ul li {
    margin: 0 3px;
    padding: 5px;
    font-size: 13px;
  }

  /* listing details page end */
}
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .form50 {
    gap: 0;
  }
  .form100 {
    display: block;
  }

  .search-hotel .form-content input.room {
    margin-left: 20px;
  }

  .form50 div {
    margin: 0 3px;
  }
  .form100 div {
    width: 100%;
    margin: 0;
  }
  .applyform .WL_innercardholder {
    display: block;
  }
  .applyform .WL_card_submitbtn {
    margin-top: 10px;
  }
  .WL_creditfrmcont_mwrp .form-checkbox .form-check input {
    width: 40px;
  }
  .toprightsec ul {
    gap: 0;
  }
  .toprightsec ul li {
    margin-left: 24px;
  }
  .listing-details-header {
    display: block;
  }
  .listing-details-header .rating-content {
    justify-content: flex-end;
  }
  .WL_collection_right {
    width: 50%;
  }
  .custom-hotel-details-card .WL_inner_review_cont {
    margin-left: 20px;
  }
  .listing-box .amenities .price-content ul li {
    font-size: 12px;
  }
  .profile-image-validation {
    float: left;
    margin-top: 0;
  }
  .referralcode span {
    margin-right: 20px;
  }
  .accounttotalsection .leftmenu {
    width: 30%;
    margin-right: 20px;
  }
  .accounttotalsection .rightsection {
    width: 65%;
  }
  .referrallink span {
    width: 80%;
  }
  .accounttable {
    overflow-x: auto;
  }
  .accounttotalsection {
    gap: 0 !important;
  }
}

@media (max-width: 991px) {
  /* home banner start */

  .search-hotel {
    margin-top: 250px;
  }

  .search-hotel .form-content .left {
    flex-wrap: wrap;
    width: 100%;
  }

  .search-hotel .form-content input,
  .search-hotel .form-content .form-box,
  .search-hotel .form-content .right {
    width: 100%;
  }

  .search-hotel .form-content input {
    padding-left: 0 !important;
    border-right: 0 !important;
  }

  .search-hotel .form-content button {
    border-radius: 25px;
    line-height: 80px;
  }

  ul.banner-item-list li {
    width: 50%;
    padding-bottom: 20px;
  }

  .search-hotel .tab-container.hourly-stay .form-box.checkin {
    width: 100%;
    border-right: 0px;
  }

  .search-hotel .form-content input.location {
    background-position: 100% 20px;
  }

  .search-hotel .form-content input.check-in {
    background-position: 100% 8px;
  }

  .search-hotel .form-content input.check-out {
    background-position: 100% 20px;
  }

  .search-hotel .tab-container.hourly-stay input.check-in {
    background-position: 100% 20px;
  }

  /* .search-hotel .tab-container.hourly-stay .right {
    padding-right: 15px;
    padding-bottom: 20px;
  } */

  .search-hotel .tab-container.hourly-stay .right,
  .inner-banner .search-hotel .tab-container .right {
    padding-right: 0px;
    padding-top: 5px;
    border-radius: 15px;
  }

  .right button#magnifier-btn {
    margin-bottom: 5px;
  }

  button.back-to-top.btn.btn-primary {
    display: none !important;
  }

  .inner-banner .search-hotel .tab-container button {
    margin: 0;
  }

  .search-hotel .tab-container.hourly-stay .right button {
    padding: 0;
  }

  /* home banner end */

  /* popular destinations start */

  .popular-destinations ul.destinations-list li {
    width: 33.33% !important;
  }

  /* popular destinations end */

  /* app wrapper start */
  .app-wrapper {
    text-align: center;
  }

  .app-wrapper ul.app-icon {
    justify-content: center;
  }

  .app-wrapper .app-pic {
    padding-right: 15px;
  }

  .app-wrapper .app-txt {
    padding-left: 15px;
  }

  .app-wrapper .app-pic {
    padding-bottom: 20px;
  }

  .app-wrapper p {
    padding-right: 0;
  }

  /* app wrapper end */

  /* listing page start */
  .listing-wrapper .col-lg-3,
  .listing-wrapper .col-lg-9 {
    width: 100%;
  }

  /* listing page end */

  /* listing details page start */

  .listing-details-header .left-txt,
  .listing-details-header .rating-content {
    width: 100%;
  }

  .listing-details-header .rating-content {
    justify-content: flex-end;
    padding-top: 15px;
    justify-content: left;
    flex-direction: row-reverse;
  }

  .listing-details-header .rating-txt {
    margin-left: 0;
    margin-right: 10px;
  }

  .listing-details-header .rating-content .feedback,
  .listing-details-header .rating-content .reviews {
    text-align: left;
  }

  .listing-details-header .icon-list a {
    margin: 0 10px;
  }

  .details-slider .slide-item img {
    height: 390px;
  }

  .popup-slider ul li {
    margin-bottom: 10px;
  }

  .popup-slider ul li img {
    height: 190px;
  }

  .customer-rating .categories ul {
    column-count: 2;
    margin: 0 -10px;
  }

  .customer-rating .categories ul li {
    padding: 0 10px;
  }

  .customerreview {
    padding: 10px !important;
  }

  .customer-review .slick-list {
    margin: 0 -10px;
  }

  .topweather {
    margin-left: 5%;
  }

  /* listing details page end */

  .listing-wrapper .row {
    flex-direction: column-reverse;
  }
}

@media (max-width: 767px) {
  h2 {
    font-size: 20px;
    line-height: 30px;
  }

  /* home banner start */
  .search-hotel {
    margin-top: 150px;
  }

  .search-hotel .nav-tabs .nav-link {
    padding: 15px 30px;
  }

  .search-hotel .form-content button {
    line-height: 60px;
  }

  ul.banner-item-list li {
    width: 100%;
  }

  .search-hotel .form-content input.location {
    background-position: 100% 20px;
  }

  .search-hotel .form-content input.check-in {
    background-position: 100% 8px;
  }

  .search-hotel .form-content input.check-out {
    background-position: 100% 20px;
  }

  /* home banner end */

  /* popular destinations start */

  .popular-destinations {
    padding-top: 30px;
    padding-bottom: 20px;
  }

  .popular-destinations .popular-destinations-header {
    padding-bottom: 10px;
  }

  .popular-destinations ul.destinations-list li {
    width: 100% !important;
  }

  .popular-destinations ul.destinations-list li img {
    height: auto;
  }

  .popular-destinations .popular-destinations-header h2,
  .popular-destinations .popular-destinations-header .col-md-5 {
    text-align: center;
  }

  .popular-destinations .popular-destinations-header h2 {
    padding-bottom: 20px;
  }

  /* popular destinations end */

  /* app wrapper start */
  .app-wrapper {
    text-align: center;
    padding-bottom: 30px;
  }

  .app-wrapper ul.app-icon {
    justify-content: center;
  }

  .app-wrapper .app-txt {
    padding-left: 15px;
  }

  .app-wrapper .app-pic {
    padding-bottom: 20px;
  }

  .app-wrapper p {
    padding-right: 0;
    padding-bottom: 20px;
  }

  .app-wrapper h2 {
    padding-bottom: 20px;
  }

  .app-wrapper ul.app-icon li {
    padding-right: 15px;
  }

  /* app wrapper end */
  .guestpop {
    width: auto;
  }

  /**  Checkout form select   **/
  .WL_cardType_selectbox .form-select {
    width: 100%;
  }

  .conformedpop .MuiDialogActions-root {
    display: block;
  }
  .conformedpop .MuiDialogActions-root button {
    margin-left: 0;
    margin-top: 10px;
  }

  .destinations-list li:nth-child(1) .caption,
  .destinations-list li:nth-child(2) .caption,
  .destinations-list li:nth-child(3) .caption {
    position: relative;
    top: 0;
    left: 0;
  }
  .popular-destinations .destinations-list {
    padding-top: 0;
  }
}

@media (min-width: 1500px) {
  .toprightsec ul li:last-child .dropdown-menu,
  .toprightsec ul li:nth-last-child(2) .dropdown-menu {
    margin-left: 0px;
    inset: 0px auto auto 0px !important;
  }
  .toprightsec .dropdown-menu:before {
    left: 12px;
  }
  .toprightsec .dropdown-menu:after {
    left: 8px;
  }
  .toprightsec .dropdown-menu {
    margin-left: 0px;
  }
}

@media (min-width: 1300px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 500px) {
  .search-hotel .form-content input.room {
    margin-left: 30px;
  }
}

@media (max-width: 1400px) {
  .home-banner {
    min-height: auto;
    margin-top: 0;
    background-size: 100%;
  }
  .home-banner-below {
    margin-top: 60px;
    margin-bottom: 60px;
  }
}

@media (max-width: 1366px) {
  /* home banner start */

  .search-hotel {
    margin-top: 515px;
  }

  /* home banner end */
}

@media (max-width: 1280px) {
  /* home banner start */

  .search-hotel {
    margin-top: 480px;
  }

  /* home banner end */
}

@media (max-width: 1199px) {
  /* home banner start */

  .search-hotel {
    margin-top: 378px;
  }

  /* home banner end */

  /* my points */
  .accounttotalsection .accountpoint .nav-tabs .nav-link {
    margin-right: 25px;
  }

  /* my points */
}

@media (max-width: 991px) {
  /* home banner start */
  .home-banner-below {
    margin-bottom: 0;
  }
  .search-hotel {
    margin-top: 345px;
  }

  .search-hotel .form-content .left {
    flex-wrap: wrap;
    width: 100%;
  }

  .search-hotel .form-content input,
  .search-hotel .form-content .form-box,
  .search-hotel .form-content .right {
    width: 100%;
  }

  .search-hotel .form-content input {
    padding-left: 0 !important;
    border-right: 0 !important;
  }

  .search-hotel .form-content button {
    border-radius: 25px;
    line-height: 80px;
  }

  ul.banner-item-list li {
    width: 50%;
    padding-bottom: 20px;
  }

  /* home banner end */

  /* popular destinations start */

  .popular-destinations ul.destinations-list li {
    width: 33.33% !important;
  }

  /* popular destinations end */

  /* app wrapper start */
  .app-wrapper {
    text-align: center;
  }

  .app-wrapper ul.app-icon {
    justify-content: center;
  }

  .app-wrapper .app-pic {
    padding-right: 15px;
  }

  .app-wrapper .app-txt {
    padding-left: 15px;
  }

  .app-wrapper .app-pic {
    padding-bottom: 20px;
  }

  .app-wrapper p {
    padding-right: 0;
  }

  /* app wrapper end */
  .bidchecking {
    display: block;
    width: 100%;
    margin-top: 20px;
  }
  .search-hotel .tab-container.hourly-stay .form-box.checkin {
    margin-top: 20px;
  }
  /* .bidchecking .form-box, .search-hotel .tab-container.hourly-stay .form-box.checkin{
    background-image: url(../../../public/img/icon-calendar.svg);
  background-repeat: no-repeat;
  background-size: 20px;
  background-position: 100% 50%;
  }
  .bidchecking .form-box.check-out{
    background-image: url(../../../public/img/icon-calendar.svg);
  } */
  .search-hotel .tab-container.hourly-stay .form-box.checkin button,
  .bidchecking .form-box button {
    display: none;
  }
}
@media (max-width: 1024px) {
  .inner_mbox_offercard {
    flex: 100%;
  }
}

@media (min-width: 1024px) {
  .inner_mbox_offercard {
    flex: 1 50%;
  }
}

@media (max-width: 1024px) {
  .listing-details-header .icon-list {
    padding-right: 35px;
  }
  .hotel-listing-box .price-content ul li {
    font-size: 12px;
  }
  .time-slot ul li {
    font-size: 12px;
  }
  .myFavTextmiddle h3 {
    font-size: 16px;
  }
  .myFavTextmiddle .address p {
    font-size: 11px;
  }
}

/* Get Special section */
@media (max-width: 1023px) {
  .getspecial {
    display: block;
    text-align: center;
  }

  .getspecial-form {
    margin: 15px auto 0 auto;
  }

  .footerpadding {
    padding-left: 0;
  }

  /* account section */
  .accounttotalsection {
    gap: 30px;
  }

  /* account section */
  /* ////////////Mybooking page//////////////// */
  .accounttotalsection .nav-tabs .nav-link {
    margin-right: 10px;
  }

  .bookingbox {
    display: block;
  }

  .bookingboxlft {
    width: 100%;
    margin-bottom: 15px;
  }

  .bookingboxrgt {
    width: auto;
    text-align: center;
    display: inline-block;
  }

  .bookingboxrgt a {
    margin: 0 10px 0 0;
  }

  /* / //////////// Guest Bid User Checkout Page //////////////// / */

  .WL_cc_cardicon img {
    margin: 0 10px;
    width: 12%;
  }

  .WL_collection_left {
    width: 80%;
    padding: 0 15px 0 0;
  }

  /* .WL_collection_right {
    width: 20%;
  } */
  .hotel-details-card-right .WL_collection_right {
    width: auto;
  }

  .WL-custmpayradio .form-check-input:checked:before {
    left: 3.5px;
    top: 4px;
  }

  /* / //////////// Guest Bid User Checkout Page //////////////// / */

  .reviewstar img {
    width: 18px;
  }
  .container p {
    text-align: inherit;
  }
  .WL_checkouttime_text {
    padding-left: 10px;
  }
}

/* Get Special section */
@media (max-width: 1200px) {
  .reviewcontent {
    padding-right: 0;
  }
}
@media (max-width: 768px) {
  .WL_inner_review_cont {
    margin-left: 0;
  }
  .favreviewtext_cont {
    margin-top: 8px;
  }
  .WL_collection_left {
    width: 80% !important;
  }
  .custom-hotel-details-card .WL_collection_left {
    width: 100% !important;
  }
}

@media (max-width: 767px) {
  /* Common section */
  h2 {
    font-size: 20px;
    line-height: 30px;
  }

  /* Header section */

  .toprightsec ul {
    justify-content: center;
  }

  .logo {
    text-align: center;
  }
  .preference_mwrap h2,
  .ofer_mwrp h2,
  .partner_mwrp h2 {
    padding-bottom: 20px;
  }
  .carousel_st .slick-prev,
  .carousel_st .slick-prev:hover,
  .carousel_st .slick-prev:focus {
    top: -47px !important;
  }
  .carousel_st .slick-next,
  .carousel_st .slick-next:hover,
  .carousel_st .slick-next:focus {
    top: -33px !important;
  }

  /* Header section */

  /* Get Special section */

  .getspecial-form {
    width: auto;
  }

  .getspecial {
    margin: 50px 0;
  }

  /* Get Special section */

  /* What Our Guests Say */

  .reviewcontent {
    padding-right: 0;
    text-align: center;
  }

  .reviewbox {
    max-width: fit-content;
  }
  .card_list .reviewbox {
    width: 100%;
  }

  .reviewcontent {
    padding-right: 0;
  }

  /* What Our Guests Say */

  /* home banner start */
  .search-hotel {
    margin-top: 150px;
  }

  .search-hotel .nav-tabs .nav-link {
    padding: 15px 30px;
  }

  .search-hotel .form-content button {
    line-height: 60px;
  }

  ul.banner-item-list li {
    width: 100%;
  }

  .search-hotel .form-content input.location {
    background-position: 100% 20px;
  }

  .search-hotel .form-content input.check-in {
    background-position: 100% 8px;
  }

  .search-hotel .form-content input.check-out {
    background-position: 100% 20px;
  }

  .home-banner {
    margin-bottom: 50px;
  }

  /* home banner end */

  /* popular destinations start */

  .popular-destinations {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .popular-destinations .popular-destinations-header {
    padding-bottom: 10px;
  }

  .popular-destinations ul.destinations-list li {
    width: 100% !important;
  }

  .popular-destinations ul.destinations-list li img {
    height: auto;
  }

  .popular-destinations .popular-destinations-header h2,
  .popular-destinations .popular-destinations-header .col-md-5 {
    text-align: center;
  }

  .popular-destinations .popular-destinations-header h2 {
    padding-bottom: 20px;
  }

  /* popular destinations end */

  /* app wrapper start */
  .app-wrapper {
    text-align: center;
    padding-bottom: 50px;
  }

  .app-wrapper ul.app-icon {
    justify-content: center;
  }

  .app-wrapper .app-txt {
    padding-left: 15px;
  }

  .app-wrapper .app-pic {
    padding-bottom: 20px;
  }

  .app-wrapper p {
    padding-right: 0;
    padding-bottom: 20px;
  }

  .app-wrapper h2 {
    padding-bottom: 20px;
  }

  .app-wrapper ul.app-icon li {
    padding-right: 15px;
  }

  /* app wrapper end */

  .partner_mwrp,
  .ofer_mwrp {
    padding-bottom: 50px;
  }

  /* ////////////Login page//////////////// */
  .logintab {
    max-width: 297px;
  }

  .logintab button {
    width: 146px;
  }

  .logtotal {
    margin: 50px auto;
  }

  .form50 div {
    width: 100%;
  }

  .form50 {
    display: block;
  }

  .form-checkbox .form-check {
    display: inline-block;
  }

  .customform label {
    font-size: 12px;
    line-height: 20px;
  }

  /* ////////////Common page//////////////// */

  .commonpage,
  .myaccountpage {
    padding: 30px 0;
  }

  h1 {
    font-size: 25px;
  }

  /* ////////////Myaccount page//////////////// */
  .accounttotalsection {
    display: block;
  }

  .accounttotalsection .leftmenu,
  .accounttotalsection .rightsection {
    width: 100%;
  }

  .account-titlearea {
    padding-bottom: 30px;
  }

  .accounttotalsection .rightsection {
    margin-top: 40px;
  }

  /* ////////////MyWallet page//////////////// */
  .accountwallet .pointsarea {
    display: block;
  }

  .walletdropdown {
    margin-top: 15px;
  }

  .accounttable {
    overflow-y: auto;
    padding: 0 10px;
  }

  .accounttable table {
    width: 660px;
  }

  /* ////////////My referral//////////////// */
  .referralcode,
  .referralcode label {
    display: block;
  }

  .referralcode span {
    display: inherit;
    float: left;
    margin: 0 10px 0 0;
    width: 80%;
  }

  .referralcode label {
    margin-bottom: 15px;
  }

  .accountpoint .pointslft {
    width: auto;
  }

  /* listing page start */
  .listing-wrapper {
    margin-bottom: 30px;
  }

  .listing-box .slide-box,
  .listing-box .right {
    width: 100%;
  }

  .listing-box .right {
    padding-left: 0;
  }

  .listing-box .slide-box .slide-item img {
    height: 220px;
    width: 100%;
  }

  .customer-review .slick-next,
  .customer-review .slick-next:hover,
  .customer-review .slick-next:focus {
    right: 0px;
    top: -25px !important;
  }

  .customer-review .slick-prev,
  .customer-review .slick-prev:hover,
  .customer-review .slick-prev:focus {
    left: auto;
    right: 35px;
    top: -40px !important;
  }

  /* listing page end */

  /* listing details page start */

  .details-slider {
    width: 100%;
  }

  .popup-slider {
    width: 100%;
    padding: 10px 0 0;
  }

  .popup-slider ul {
    display: flex;
    margin: 0 -5px;
  }

  .popup-slider ul li {
    margin: 0 5px;
    width: 50%;
  }

  .hotel-listing-box .slide-box {
    width: 100%;
  }

  .hotel-listing-box .slide-box .slide-item img {
    height: 190px;
  }

  .hotel-listing-box .middle {
    width: 60%;
    padding-left: 0;
  }

  .hotel-listing-box .price-content {
    width: 40%;
  }

  .hotel-listing-box .price-content div {
    width: 100%;
  }

  .topweather {
    position: absolute;
    top: 130px;
    right: 50%;
    font-size: 14px;
    transform: translateX(50%);
  }
  .hotel-listing-box .price-content ul li {
    font-size: 12px;
  }
  .time-slot ul li {
    font-size: 12px;
    margin-bottom: 5px;
  }

  /* listing details page end */

  /* //////////// Guest Bid user page//////////////// */

  .WL_inner_leftwrp {
    margin-bottom: 36px;
  }

  .WL_collection_left {
    width: 100%;
  }

  .WL_collection_right {
    width: 100%;
    margin: 20px 0 20px 0;
  }

  .WL_right_rivewbox {
    display: block;
  }

  .WL_inner_review_cont {
    flex: 1 1;
    margin-left: 0;
  }

  .WL_personalheading {
    font-size: 20px;
  }

  .WL_persnal_wrap {
    padding: 21px 19px;
  }

  /* //////////// Guest Bid user page//////////////// */

  /* / //////////// Guest Bid User Checkout Page //////////////// / */

  .WL_inner_leftwrp {
    margin-bottom: 0;
    padding-bottom: 15px;
  }
  .WL_paymentwrp {
    margin-bottom: 10px;
  }

  .WL_collection_left {
    width: 100%;
    padding: 0 0 0 0;
  }

  .WL_collection_right {
    width: 100%;
    margin: 20px 0 20px 0;
  }

  .WL_review_mwrp {
    display: block;
  }

  .WL_right_rivewbox {
    display: block;
  }

  .WL_inner_review_cont {
    flex: 1 1;
    margin-left: 0;
  }

  .WL_personalheading {
    font-size: 20px;
  }

  .WL_persnal_wrap {
    padding: 21px 19px;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .WL_cc_cardicon img {
    margin: 0 5px;
    width: 16%;
  }

  .WL_cc_cardicon {
    padding: 0 0;
  }

  .WL-custmpayradio {
    display: block;
  }

  .WL-custmpayradio .form-check {
    padding: 0 0 0 0;
    margin: 0 0 11px 0;
  }

  .WL_creditfrmcont_mwrp .formsubmit {
    width: 100%;
    margin: 14px auto;
    display: block;
  }

  .WL-custmpayradio .form-check-input:checked:before {
    left: 3.5px;
    top: 3.1px;
  }

  .WL_coll_slider {
    width: auto;
    height: auto;
  }

  /* / //////////// Guest Bid User Checkout Page //////////////// / */
  /* / //////////// Contact us Page start //////////////// / */
  .WL_innercontacttext {
    width: 100%;
    margin-bottom: 20px;
  }
  .WL_contacttextwrp {
    text-align: center;
  }

  /* / //////////// Contact us Page End //////////////// / */
  .errormessagesearch {
    bottom: -40px;
  }
}

@media (max-width: 575px) {
  /* listing page start */

  .hotel-title-and-star-container {
    margin-bottom: 7.7px;
  }

  .hotel-amenities ul li {
    flex: 1 0 130px;
  }

  .listing-box .top-bar {
    padding-bottom: 10px;
  }

  .listing-box .top-bar .left-txt {
    width: 100%;
    padding-bottom: 10px;
  }

  .listing-box .top-bar .rating-content {
    width: 100%;
    justify-content: left;
    flex-direction: row-reverse;
  }

  .listing-box .top-bar .rating-txt {
    margin-left: 0;
    margin-right: 10px;
  }

  .listing-box .top-bar .rating-content .feedback,
  .listing-box .top-bar .rating-content .reviews {
    text-align: left;
  }

  .listing-box .address {
    padding-right: 0;
  }

  .listing-box .amenities .amenities-item {
    width: 100%;
    padding-right: 0;
  }

  .listing-box .amenities .price-content {
    width: 100%;
    margin-top: 15px;
  }

  .listing-header .header-left,
  .listing-header .header-right {
    width: 100%;
  }

  .listing-header .header-right .map {
    margin-inline: auto;
    margin-bottom: 8px;
  }

  .list-pagination .left {
    width: 100%;
    padding-right: 0px;
    display: flex;
    justify-content: center;
  }

  .list-pagination .right {
    width: 100%;
    text-align: center;
    padding-top: 10px;
  }

  .back-to-top {
    margin: 10px auto 10px;
    display: block;
    width: 150px;
    text-align: center;
  }

  /* listing page end */

  /* listing details page start */

  .details-slider .slide-item img {
    height: 300px;
  }

  .popup-slider ul li img {
    height: 120px;
  }

  .hotel-listing-box {
    padding: 15px;
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 5px #00000040;
    margin-bottom: 1rem;
  }

  .recommendedTagBox {
    /* display: none; */
    max-width: fit-content;
  }

  .recommendedTagBox-mobile {
    display: grid;
    margin-top: 1rem;
  }

  .amenities-item .title {
    margin-top: 1rem !important;
    margin-bottom: 0.25rem !important;
  }

  .hotel-listing-box .middle {
    width: 100% !important;
    padding-right: 0px;
  }

  .hotel-listing-box .price-content {
    width: 100% !important;
    padding-top: 15px;
  }

  .nearby-location-map {
    margin-top: 0;
    margin-bottom: 30px;
  }

  .nearby-location-item {
    padding-bottom: 30px;
  }

  .customer-rating .categories ul {
    column-count: 1;
  }

  .customer-rating {
    padding-bottom: 20px;
  }

  .customer-review {
    padding-bottom: 30px;
  }

  .house-rules .title {
    padding-bottom: 0;
  }

  .house-rules .content-box {
    padding: 15px;
  }

  .house-rules .content-row .left {
    width: 100%;
    padding-right: 0;
  }

  .house-rules .content-row {
    padding-bottom: 0;
  }

  .house-rules .content-row .right {
    width: 100%;
  }

  .listing-details {
    padding-bottom: 30px;
  }

  .listing-details-header .title,
  .hotel-listing-box .title,
  .customer-rating .title,
  .customer-review .title,
  .house-rules .title {
    font-size: 20px;
  }

  /* listing details page end */

  .pophours .MuiBox-root,
  .pophours {
    width: 100%;
  }

  .pref_imgbox {
    /* height: auto; */
  }
  .listing-header {
    /* padding: 15px; */
    padding-bottom: 0;
    margin: 15px 0;
  }
  .listing-box {
    box-shadow: none;
  }
  .reset-now {
    width: 100%;
    margin: 0 auto;
  }
  .popbox {
    width: auto;
  }
  .pointsarea {
    display: flex;
    gap: 16px;
    flex-wrap: wrap;
    justify-content: center !important;
  }
  .accounttotalsection .accountpoint .nav-tabs .nav-link {
    margin-right: 0;
    width: auto;
  }
  .accountpoint.notificaton .bookingbox {
    padding: 20px;
  }
  .WL_creditfrmcont_mwrp .form-checkbox .form-check input {
    width: 22px;
  }
  .form-checkbox .form-check {
    display: flex;
  }
  .customform label {
    width: 90%;
    text-align: left;
  }
  .accounttotalsection .bookingtab {
    flex-wrap: nowrap;
  }
  .accounttotalsection .rightsection {
    padding: 15px;
  }
  .referralcode span {
    width: 78% !important;
  }
  .sharePopup {
    width: 85% !important;
  }
  .reviewlist {
    padding-left: 0;
  }
  .customratepop {
    padding: 15px !important;
  }
}

/* ////////////Login page//////////////// */

@media (max-width: 420px) {
  /* Header section */
  .toprightsec ul {
    gap: 12px;
  }

  /* Header section */

  /* app wrapper*/

  .app-wrapper ul.app-icon li {
    margin-bottom: 10px;
  }

  /* app wrapper*/

  /* Footer */
  .footer-socialarea,
  .copyright {
    text-align: center;
  }

  /* Footer */

  .pagination-div {
    display: block;
  }
  .pagination-div nav {
    margin-left: -15px;
    margin-top: 10px;
  }
}

/* Rate popv up */

@media (max-width: 767px) {
  .customratepop {
    height: 450px;
    overflow-y: auto;
    width: 90% !important;
    overflow-x: hidden;
  }
  .customratepop ul li {
    display: block;
  }
  .customratepop label span svg {
    margin-left: 6px;
  }
  .reviewbox {
    max-width: 100% !important;
    margin: 15px 0;
  }
  .getspecial {
    padding: 60px 25px;
  }
  .container p {
    text-align: inherit;
  }
  footer {
    padding-top: 28px;
  }
  .footerlogo,
  footer ul.footlocation li {
    margin-bottom: 15px;
  }
  footer h3 {
    padding-bottom: 0;
    padding-top: 10px;
  }
  footer ul li {
    margin-bottom: 5px;
  }
  .home-banner-below {
    padding-bottom: 30px;
  }
  .timeslot-desk {
    display: none;
  }
  .timeslot-mob {
    display: block;
    padding-top: 20px;
  }
}
@media (max-width: 400px) {
  .accounttotalsection .nav-tabs .nav-link {
    margin-right: 0;
  }
  .app-wrapper ul.app-icon {
    display: block;
  }
}

@media only screen and (min-width: 1280px) and (max-width: 1366px) {
  .home-banner {
    margin-top: -110px;
    background-size: 97%;
    padding-bottom: 100px;
  }
  .home-banner .search-hotel .form-content .left {
    padding-bottom: 10px;
  }
  .home-banner .search-hotel {
    margin-top: 503px;
  }
  .home-banner-below {
    margin-top: -60px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1300px) {
  .home-banner {
    background-size: 100%;
    margin-top: -85px;
  }
  .home-banner .search-hotel {
    margin-top: 487px;
  }
}

@media all and (device-width: 1024px) and (device-height: 768px) and (orientation: landscape) {
  .home-banner .search-hotel {
    margin-top: 377.5px;
  }
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .home-banner .search-hotel {
    margin-top: 268.5px;
  }
}
@media all and (device-width: 954px) and (device-height: 768px) and (orientation: landscape) {
  .home-banner .search-hotel {
    margin-top: 348px;
  }
}
@media all and (device-width: 768px) and (device-height: 954px) and (orientation: portrait) {
  .home-banner .search-hotel {
    margin-top: 320px;
  }
}
@media all and (device-width: 820px) and (device-height: 1180px) and (orientation: portrait) {
  .home-banner .search-hotel {
    margin-top: 290px;
  }
}
@media all and (device-width: 1180px) and (device-height: 820px) and (orientation: landscape) {
  .home-banner .search-hotel {
    margin-top: 444px;
  }
}

@media only screen and (max-width: 575px) {
  .hlb-top-bar .room_title {
    width: 100%;
    margin-bottom: 0;
  }
  .home-banner {
    background-size: 94%;
  }
  .toprightsec ul li.langdrop .dropdown-toggle {
    line-height: 14px;
  }
  .search-hotel {
    margin-top: 165px !important;
  }
  .search-hotel.search-list-header {
    margin-top: 70px !important;
    margin-bottom: 0;
  }
  .hotel-listing-box-content .slick-list {
    height: 190px;
    border-radius: 10px;
  }
  .small-screens-hotel-list {
    padding-inline: 0.25rem !important;
  }
  .small-screens-hotel-list .box {
    padding: 15px;
  }
  .hotel-title-and-star-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .hotel-title-and-star-container .hotel-title {
    font-size: 1.25rem;
  }
  .price-content ul li span {
    font-size: 1.1rem;
  }
  .nearby-location-item .title,
  .nearby-location-description .title {
    padding-bottom: 0.875rem;
  }
  .listing-box .top-bar .rating-content .feedback,
  .listing-box .top-bar .rating-content .reviews,
  .listing-details-header .rating-content .feedback,
  .listing-details-header .rating-content .reviews {
    text-align: left;
  }

  .listing-details-header .title-stars-container {
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
  }

  .listing-details-header .top-part .title {
    line-height: 1.4rem;
    flex-grow: 1;
  }

  .address {
    margin-top: 15px;
  }

  .hide_mobile {
    display: none;
  }

  .WL_cc_cardicon .unionPayLogo {
    margin: 0;
  }

  .WL_cc_cardicon .samsungLogo {
    margin: 0;
    height: 17px !important;
    width: auto !important;
  }

  .WL_checkbx_crdcard a {
    cursor: pointer;
    font-weight: 600;
    color: rgb(55, 74, 119);
  }
  .inner_offerbox {
    height: 120px;
  }
  .inner_offerbox img {
    width: 224px;
    height: auto;
  }
  .topweather {
    margin-right: 0;
    margin-left: 0;
  }
  .small-bidding-map {
    width: 100%;
  }
}

/* share modal */

.sharePopup {
  /* position: absolute; */
  position: fixed;
  left: 50%;
  z-index: 1;
}
.sharePopup .copyLinkBtn {
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: background 0.1s linear, border-color 0.1s linear,
    color 0.1s linear;
  height: 43px;
}
.clipbd-popcloses {
  cursor: pointer;
  font-size: 16px;
}
.clipbd-popcloses svg {
  font-size: 18px !important;
}

.sharePopup {
  background: #fff;

  border-radius: 15px;
  top: -150%;
  max-width: 500px;
  width: 100%;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 0px 12px 1px rgb(0 0 0 / 10%); */
  transform: translate(-50%, -50%) scale(1.2);
  transition: top 0s 0.2s ease-in-out, opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}
.sharePopup.show {
  top: 50%;
  opacity: 1;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
  transition: top 0s 0s ease-in-out, opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}
.sharePopup :is(.modalHeader, .icons, .copyToClipboardArea) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sharePopup .modalHeader {
  border-bottom: 1px solid #ebedf9;
  background: #5287b3;
  border-radius: 15px 0px 15px 0px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  padding: 10px 0 15px;
  padding: 10px 25px 15px 25px;
}
.sharePopup .popclose  {
  position: absolute;
  top: 5px;
  /* right: 25px !important; */
}
.sharePopup .popclose svg path {
 color: #fff;
 stroke: #fff;
}

.sharePopup .modalHeader .close,
.sharePopup .icons a {
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.sharePopup .modalHeader .close {
  color: #878787;
  font-size: 17px;
  background: #f2f3fb;
  height: 33px;
  width: 33px;
  cursor: pointer;
}
.sharePopup .modalHeader .close:hover {
  background: #ebedf9;
}
.sharePopup .content {
  margin: 20px 0;
  padding: 0 25px 25px;
}
.sharePopup .icons {
  margin: 15px 0 20px 0;
}
.sharePopup .content p {
  font-size: 16px;
}
.sharePopup .content .icons a {
  height: 50px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent;
}
.sharePopup .icons a i {
  transition: transform 0.3s ease-in-out;
}

.sharePopup .icons a:hover i {
  transform: scale(1.2);
}
.sharePopup .content .copyToClipboardArea {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}
.sharePopup .copyToClipboardArea.active {
  border-color: #7d2ae8;
}
.sharePopup .copyToClipboardArea i {
  width: 50px;
  font-size: 18px;
  text-align: center;
}
.sharePopup .copyToClipboardArea.active i {
  color: #7d2ae8;
}
.sharePopup .copyToClipboardArea input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  padding: 0 5px;
}
.sharePopup .copyToClipboardArea button {
  color: #fff;
  padding: 5px 18px;
  background: #5287b3;
}
.sharePopup .copyToClipboardArea button:hover {
  background: #5287b3;
}

.copiedToClipboard {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 45px;
  padding: 10px;
  background: #dbffdb;
  color: #107010;
  /* border-radius: 15px 15px 0 0; */
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.copiedToClipboard p svg {
  font-size: 24px;
}

/* .share-modal-backdrop {
  background: rgb(94 88 88 / 78%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
} */

/* end share modal */

.bid_now__mobile_container {
    width: 100%;
    border-radius: 0 25px 0 0;
    background-color: #f1f1f1;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    padding-bottom: 5px;
}

.bid_now__input_container {
    display: flex;
    background-color: #fff;
    flex-direction: column;
    padding-inline: 20px;
    flex:1;
}

.loading-indicator:before {
  content: "";
  background: #000000cc;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999 !important;
}
.circle-border {
  position: fixed;
  left: 50%;
  top: 50%;
  /* top: 0; */
  transform: translate(-50%, -50%);
  overflow: hidden;
  z-index: 99999 !important;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
  width: 48px;
  height: 48px;
  border: 4px solid #0d6efd;
  border-bottom-color: #374a77;
  border-radius: 50%;
}



@-webkit-keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.labelContainer {
  position: absolute;
  top: 10px;
  left: -9px;
  background-color: #e20606;
  width: 100%;
  height: 18px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px 4px 4px 0;
  z-index: 1;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
}

.labelContainer .labelTail {
  left: 0;
  bottom: 0;
  transform: translateY(100%);
  position: absolute;
  border-width: 5px;
  border-color: #27302a #27302a transparent transparent;
  border-style: solid;
  width: 1px;
  height: 1px;
}

.labelContainer .labelText {
  color: #fff;
  font-size: 0.75rem;
  font-weight: 800;
  text-align: center;
  margin-left: 10px;
}

:global(.arabic-lang) .labelContainer {
  left: auto;
  right: -10px;
  border-radius: 4px 4px 0 4px;
}

:global(.arabic-lang) .labelContainer .labelTail {
  left: auto;
  right: 0;
  border-color: #27302a transparent transparent #27302a;
}

:global(.arabic-lang) .labelContainer .labelText {
  margin-left: -10px;
}

.select_hours {
  cursor: pointer;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  height: 23px;
  line-height: 1.44rem;
}

.mobile__reservationForm__container {
  width: 100%;
  border-radius: 0 25px 0 0;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: hidden;
  padding-bottom: 5px;
}

.mobile__reservationForm__input_container {
  display: flex;
  background-color: #fff;
  flex-direction: column;
  padding-inline: 20px;
  flex: 1;
  padding-bottom: 6px;
}
.mobile__reservationForm__input_label {
  font-size: 14px;
  color: #9d9d9d;
  font-weight: 600;
}
.mobile__reservationForm__date__picker {
  input {
    all: unset;
  }

  fieldset {
    all: unset;
  }
  .mobile__reservationForm__guests_input {
    font-family: "Dubai-Regular", sans-serif;
    font-size: 1rem;
    color: #0d6efd;
    flex: 1;
  }
}

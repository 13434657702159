@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .arabic-lang .react-tel-input .flag-dropdown {
    right: 0;
  }

  .arabic-lang .react-tel-input .selected-flag .flag {
    right: 12px !important;
  }

  .arabic-lang .WL-custmpayradio .form-check {
    padding: 0 0px 0 15px;
  }

  .arabic-lang .form-checkbox .form-check input {
    width: 32px;
  }

  .arabic-lang .react-tel-input .form-control {
    padding-right: 63px !important;
  }

  .arabic-lang .toprightsec ul li {
    margin-left: 0px;
    margin-right: 15px;
  }

  .arabic-lang .profile-image-validation {
    float: none;
    display: block;
  }
  .arabic-lang .accounttotalsection .leftmenu {
    margin-left: 20px;
  }
}

@media (max-width: 991px) {
  /* ===========Arabic Design===================== */
  .arabic-lang .search-hotel .form-content input.location,
  .arabic-lang .search-hotel .form-content input.check-out {
    background-position: 0% 20px;
    border-left: none;
    padding-right: 0;
  }

  .arabic-lang .inner-banner .search-hotel .tab-container .right button {
    margin-left: auto;
  }

  .arabic-lang .search-hotel .form-content input.check-in {
    background-position: 0 8px;
    padding-right: 0;
  }

  .arabic-lang .search-hotel .form-content button {
    border-radius: 25px;
  }

  .arabic-lang .search-hotel .tab-container.hourly-stay .form-box.checkin {
    border-left: none;
  }

  .arabic-lang .ofer_mwrp h2,
  .arabic-lang .partner_mwrp h2,
  .arabic-lang .preference_mwrap h2 {
    padding-right: 20px;
  }

  .arabic-lang .popular-destinations .popular-destinations-header .col-md-5,
  .arabic-lang .copyright {
    text-align: center;
  }

  .arabic-lang .reviewcontent {
    padding-left: 0;
  }

  .arabic-lang .listing-details-header .rating-content {
    justify-content: right;
  }

  .arabic-lang .listing-details-header .rating-txt {
    margin-left: 10px;
    margin-right: 0;
  }

  .arabic-lang .listing-details-header .icon-list {
    padding-left: 0px;
    padding-right: 20px;
  }

  .check-out {
    border-right: none;
  }

  .check-out .MuiFormLabel-root,
  .check-in .MuiFormLabel-root,
  .datepickercheck-in .MuiFormLabel-root {
    left: -15px;
  }

  .datepickercheck-in .MuiInputBase-root {
    padding-right: 0;
  }

  .datepickercheck-in.MuiFormControl-root {
    margin-right: 0;
  }

  .search-hotel .tab-container.hourly-stay .datepickercheck-in button {
    right: 10px;
  }

  .arabic-lang .search-hotel .tab-container.hourly-stay .right,
  .arabic-lang .inner-banner .search-hotel .tab-container .right {
    padding-left: 0px;
    padding-top: 5px;
  }

  .arabic-lang .check-out {
    border-left: none;
  }

  .arabic-lang .listing-box .right {
    padding-right: 0;
    padding-left: 0;
  }

  /* ===========Arabic Design===================== */

  /* .arabic-lang .react-tel-input .form-control {
        padding-right: 48px !important;
    } */
}

@media (max-width: 1023px) {
  /* ===========Arabic Design===================== */
  .arabic-lang .footerpadding {
    padding-right: 0px;
  }

  .arabic-lang .accounttotalsection .nav-tabs .nav-link {
    margin-left: 30px;
    margin-right: 0;
  }

  /* ===========Arabic Design===================== */
  .arabic-lang .WL_checkouttime_text {
    padding-left: 0px;
    padding-right: 10px;
  }
}

@media (max-width: 767px) {
  /* Common section */
  h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .arabic-lang .inner-banner .search-hotel .tab-container .right button {
    margin-left: auto;
  }

  /* Header section */

  .toprightsec ul {
    justify-content: center;
  }

  .logo {
    text-align: center;
  }

  /* Header section */

  /* Get Special section */

  .getspecial-form {
    width: auto;
  }

  .getspecial {
    margin: 50px 0;
  }

  /* Get Special section */

  /* What Our Guests Say */

  .reviewcontent {
    padding-right: 0;
    text-align: center;
  }

  .reviewbox {
    max-width: fit-content;
  }

  .reviewcontent {
    padding-right: 0;
  }

  /* What Our Guests Say */

  /* home banner start */
  .search-hotel {
    margin-top: 150px;
  }

  .search-hotel .nav-tabs .nav-link {
    padding: 15px 30px;
  }

  .search-hotel .form-content button {
    line-height: 60px;
  }

  ul.banner-item-list li {
    width: 100%;
  }

  .search-hotel .form-content input.location {
    background-position: 100% 20px;
  }

  .search-hotel .form-content input.check-in {
    background-position: 100% 8px;
  }

  .search-hotel .form-content input.check-out {
    background-position: 100% 20px;
  }

  .home-banner {
    margin-bottom: 50px;
  }

  /* home banner end */

  /* popular destinations start */

  .popular-destinations {
    padding-top: 50px;
    padding-bottom: 50px;
  }

  .popular-destinations .popular-destinations-header {
    padding-bottom: 10px;
  }

  .popular-destinations ul.destinations-list li {
    width: 100% !important;
  }

  .popular-destinations ul.destinations-list li img {
    height: auto;
  }

  .popular-destinations .popular-destinations-header h2,
  .popular-destinations .popular-destinations-header .col-md-5 {
    text-align: center;
  }

  .popular-destinations .popular-destinations-header h2 {
    padding-bottom: 20px;
  }

  /* popular destinations end */

  /* app wrapper start */
  .app-wrapper {
    text-align: center;
    padding-bottom: 50px;
  }

  .app-wrapper ul.app-icon {
    justify-content: center;
  }

  .app-wrapper .app-txt {
    padding-left: 15px;
  }

  .app-wrapper .app-pic {
    padding-bottom: 20px;
  }

  .app-wrapper p {
    padding-right: 0;
    padding-bottom: 20px;
  }

  .app-wrapper h2 {
    padding-bottom: 20px;
  }

  .app-wrapper ul.app-icon li {
    padding-right: 15px;
  }

  /* app wrapper end */

  .partner_mwrp,
  .ofer_mwrp {
    padding-bottom: 50px;
  }

  /* ////////////Login page//////////////// */
  .logintab {
    max-width: 297px;
  }

  .logintab button {
    width: 146px;
  }

  .logtotal {
    margin: 50px auto;
  }

  .form50 div {
    width: 100%;
  }

  .form50 {
    display: block;
  }

  .arabic-lang .form-checkbox .form-check {
    display: flex;
    align-items: flex-start;
  }

  .customform label {
    font-size: 12px;
    line-height: 20px;
  }

  /* ////////////Common page//////////////// */

  .commonpage,
  .myaccountpage {
    padding: 30px 0;
  }

  h1 {
    font-size: 25px;
  }

  /* ////////////Myaccount page//////////////// */
  .accounttotalsection {
    display: block;
  }

  .accounttotalsection .leftmenu,
  .accounttotalsection .rightsection {
    width: 100%;
  }

  .account-titlearea {
    padding-bottom: 30px;
  }

  .accounttotalsection .rightsection {
    margin-top: 40px;
  }

  /* ////////////MyWallet page//////////////// */
  .accountwallet .pointsarea {
    display: block;
  }

  .walletdropdown {
    margin-top: 15px;
  }

  .accounttable {
    overflow-y: auto;
    padding: 0 10px;
  }

  .accounttable table {
    width: 660px;
  }

  /* ////////////My referral//////////////// */
  .referralcode,
  .referralcode label {
    display: block;
  }

  .referralcode span {
    display: inherit;
    float: left;
    margin: 0 10px 0 0;
    width: 80%;
  }

  .referralcode label {
    margin-bottom: 15px;
  }

  .accountpoint .pointslft {
    width: auto;
  }

  /* listing page start */
  .listing-wrapper {
    margin-bottom: 30px;
  }

  .listing-box .slide-box,
  .listing-box .right {
    width: 100%;
  }

  .listing-box .right {
    padding-left: 0;
  }

  .listing-box .slide-box .slide-item img {
    height: 220px;
    width: 100%;
  }

  .customer-review .slick-next,
  .customer-review .slick-next:hover,
  .customer-review .slick-next:focus {
    right: 0px;
    top: -25px !important;
  }

  .customer-review .slick-prev,
  .customer-review .slick-prev:hover,
  .customer-review .slick-prev:focus {
    left: auto;
    right: 35px;
    top: -40px !important;
  }

  /* listing page end */

  /* listing details page start */

  .details-slider {
    width: 100%;
  }

  .popup-slider {
    width: 100%;
    padding: 10px 0 0;
  }

  .popup-slider ul {
    display: flex;
    margin: 0 -5px;
  }

  .popup-slider ul li {
    margin: 0 5px;
    width: 50%;
  }

  .hotel-listing-box .slide-box {
    width: 100%;
  }

  .hotel-listing-box .slide-box .slide-item img {
    height: 190px;
  }

  .hotel-listing-box .middle {
    width: 60%;
    padding-left: 0;
  }

  .hotel-listing-box .price-content {
    width: 40%;
  }

  .hotel-listing-box .price-content div {
    width: 100%;
  }

  .topweather {
    position: absolute;
    top: 130px;
    right: 50%;
    font-size: 14px;
    transform: translateX(50%);
  }

  .arabic-lang .hotel-listing-box .middle {
    padding: 0;
  }

  /* listing details page end */

  /* //////////// Guest Bid user page//////////////// */

  .WL_inner_leftwrp {
    margin-bottom: 36px;
  }

  .WL_collection_left {
    width: 100%;
  }

  .WL_collection_right {
    width: 100%;
    margin: 20px 0 20px 0;
  }

  .WL_inner_review_cont {
    flex: 1 1;
    margin-left: 0;
  }

  .WL_personalheading {
    font-size: 20px;
  }

  .WL_persnal_wrap {
    padding: 21px 19px;
  }

  /* //////////// Guest Bid user page//////////////// */

  /* / //////////// Guest Bid User Checkout Page //////////////// / */

  .WL_inner_leftwrp {
    margin-bottom: 36px;
  }

  .WL_collection_left {
    width: 100%;
    padding: 0 0 0 0;
  }

  .WL_collection_right {
    width: 100%;
    margin: 20px 0 20px 0;
  }

  .WL_review_mwrp {
    display: block;
  }

  .WL_right_rivewbox {
    display: none !important;
  }

  .WL_inner_review_cont {
    flex: 1 1;
    margin-left: 0;
  }

  .WL_personalheading {
    font-size: 20px;
  }

  .WL_persnal_wrap {
    padding: 21px 19px;
    /* padding-bottom: 0; */
    /* margin-bottom: 0; */
  }

  .WL_cc_cardicon img {
    margin: 0 5px;
    width: 13%;
  }

  .WL_cc_cardicon .unionPayLogo {
    margin: 0;
  }

  .WL_cc_cardicon .samsungLogo {
    margin: 0;
    height: 17px !important;
    width: auto !important;
  }

  .WL_cc_cardicon {
    padding: 0 0;
  }

  .WL-custmpayradio {
    display: block;
  }

  .WL-custmpayradio .form-check {
    padding: 0 0 0 0;
    margin: 0 0 11px 0;
  }

  .WL_creditfrmcont_mwrp .formsubmit {
    width: 100%;
    margin: 14px auto;
    display: block;
  }

  .WL-custmpayradio .form-check-input:checked:before {
    left: 3.5px;
    top: 3.1px;
  }

  .WL_coll_slider {
    width: auto;
    height: auto;
  }

  /* / //////////// Guest Bid User Checkout Page //////////////// / */
  /* / //////////// Contact us Page start //////////////// / */
  .WL_innercontacttext {
    width: 100%;
    margin-bottom: 20px;
  }

  /* / //////////// Contact us Page End //////////////// / */

  .arabic-lang .guestpop {
    width: auto;
    right: 16px;
  }

  .arabic-lang .listing-wrapper .content-box .title-sm {
    font-size: 16px;
    font-weight: 700;
  }

  .arabic-lang .referralcode span {
    float: right;
  }

  .arabic-lang .react-tel-input .selected-flag .flag {
    right: 65px;
  }

  .arabic-lang .react-tel-input .form-control {
    padding-right: 72px !important;
  }

  .arabic-lang .listing-details-slider .popup-slider {
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  /* ===========Arabic Design===================== */

  .arabic-lang .listing-header .header-right .map {
    margin-right: 0;
    margin-left: 0;
  }

  .arabic-lang .inner-banner .search-hotel .tab-container .right button {
    margin-left: auto;
  }

  .arabic-lang .listing-box .top-bar .rating-content {
    justify-content: right;
  }

  .arabic-lang .listing-box .top-bar .rating-txt {
    margin-left: 10px;
    margin-right: 0;
  }

  /* ===========Arabic Design===================== */

  .arabic-lang .listing-box .address {
    padding-left: 0;
  }

  .hotel-listing-box {
    padding: 15px;
    padding-bottom: 0;
    border: none;
    box-shadow: 0 0 5px #00000040;
    margin-bottom: 1rem;
  }
  .recommendedTagBox {
    /* display: none; */
    max-width: fit-content;
  }

  .recommendedTagBox-mobile {
    display: grid;
    margin-top: 1rem;
  }

  .amenities-item .title {
    margin-top: 1rem !important;
    margin-bottom: 0.25rem !important;
  }

  .house-rules .title {
    padding-bottom: 0;
  }

  .small-screens-hotel-list {
    padding-inline: 0.25rem !important;
  }
  .small-screens-hotel-list .box {
    padding: 15px;
  }
  .hotel-title-and-star-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
  .hotel-title-and-star-container .hotel-title {
    font-size: 1.25rem;
  }
  .price-content ul li span {
    font-size: 0.96rem;
  }
  .nearby-location-item .title,
  .nearby-location-description .title {
    padding-bottom: 0.875rem;
  }

  .listing-box .top-bar .rating-content .feedback-ar,
  .listing-box .top-bar .rating-content .reviews-ar,
  .listing-details-header .rating-content .feedback-ar,
  .listing-details-header .rating-content .reviews-ar {
    text-align: right;
  }

  .top-part {
    flex: 1;
  }

  .arabic-lang .listing-details-header .title {
    flex: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .hide_mobile {
    display: none;
  }

  .arabic-lang .WL-custmpayradio .form-check {
    flex-direction: row;
    gap: 14px;
    padding-right: 0;
  }

  .WL_checkbx_crdcard a {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
  }

  .WL_carddetailst_wrp {
    margin: 0 0 16px 0;
  }

  .WL_paid_wrp {
    background: #e3f2ff;
    border-radius: 12px;
    padding: 16px 30px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    margin: 0;
    margin-bottom: 16px;
  }
  .topweather {
    margin-right: 0;
    margin-left: 0;
  }
  .title-stars-container {
    align-items: flex-start;
  }
}

.arabic-lang
  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-actions {
  margin-left: 0;
  display: flex;
}

.arabic-lang
  .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar
  .MuiTablePagination-actions
  .MuiButtonBase-root {
  transform: rotate(180deg);
}

.small-bidding-map {
  width: 100%;
}

@media (min-width: 600px) {
  .arabic-lang .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    padding-right: 2px;
    padding-left: 0;
  }
}

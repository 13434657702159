.notificationContent {
  display: flex;
  gap: 16px;
  margin-top: 15px;
}
.notificationImage {
  max-width: 149px;
  width: 100%;
  height: 100px;
  border-radius: 12px;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.englishTitle {
  font-family: "Open Sans", sans-serif !important;
  text-align: left !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #3a3a3c;
}

.englishDescription {
  font-family: "Open Sans", sans-serif !important;
  text-align: left !important;
  font-size: 16px;
  line-height: 23.79px;
  color: #3a3a3c;
}

.arabicTitle {
  font-family: "Dubai-Regular",sans-serif !important;
  text-align: right !important;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  text-transform: capitalize;
  color: #3a3a3c;
}

.arabicDescription {
  font-family: "Dubai-Regular",sans-serif !important;
  text-align: right !important;
  font-size: 16px;
  line-height: 23.79px;
  color: #3a3a3c;
}

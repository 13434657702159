/* share modal */

.popupModal {
  /* position: absolute; */
  position: fixed;
  left: 50%;
  z-index: 1;
}
.popupModal .copyLinkBtn {
  outline: none;
  cursor: pointer;
  font-weight: 500;
  border-radius: 4px;
  border: 2px solid transparent;
  transition: background 0.1s linear, border-color 0.1s linear,
    color 0.1s linear;
  height: 43px;
}
.clipbd-popcloses {
  cursor: pointer;
  font-size: 16px;
}
.clipbd-popcloses svg {
  font-size: 18px !important;
}

.popupModal {
  background: #fff;
  max-width: 65%;
  height: 88%;
  border-radius: 15px;
  /* top: -150%; */
  top: 50%;
  /* max-width: 500px; */
  width: 100%;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.1);
  /* box-shadow: 0 0px 12px 1px rgb(0 0 0 / 10%); */
  transform: translate(-50%, -50%) scale(1.2);
  transition: top 0s 0.2s ease-in-out, opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}
.popupModal.show {
  max-width: 65%;
  height: 88%;
  top: 50%;
  opacity: 1;
  z-index: 9;
  pointer-events: auto;
  transform: translate(-50%, -50%) scale(1);
  transition: top 0s 0s ease-in-out, opacity 0.2s 0s ease-in-out,
    transform 0.2s 0s ease-in-out;
}
.popupModal.show #google_translate_element {
  display: block;
}
.popupModal :is(.modalHeader, .icons, .copyToClipboardArea) {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.popupModal .modalHeader {
  border-bottom: 1px solid #ebedf9;
  background: #5287b3;
  border-radius: 15px 0px 15px 0px;
  text-align: center;
  color: #fff;
  font-size: 20px;
  line-height: 27px;
  padding: 10px 0 15px;
  padding: 10px 25px 15px 25px;
}
.popupModal .popclose {
  position: absolute;
  top: 5px;
}
.popupModal .popclose svg path {
  color: #fff;
  stroke: #fff;
}

.popupModal .modalHeader .close,
.popupModal .icons a {
  display: flex;
  align-items: center;
  border-radius: 50%;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}
.popupModal .modalHeader .close {
  color: #878787;
  font-size: 17px;
  background: #f2f3fb;
  height: 33px;
  width: 33px;
  cursor: pointer;
}
.popupModal .modalHeader .close:hover {
  background: #ebedf9;
}
.popupModal .content {
  margin: 20px 0;
  padding: 0 25px 25px;
}
.popupModal .icons {
  margin: 15px 0 20px 0;
}
.popupModal .content p {
  font-size: 16px;
}
.popupModal .content .icons a {
  height: 50px;
  width: 50px;
  font-size: 20px;
  text-decoration: none;
  border: 1px solid transparent;
}
.popupModal .icons a i {
  transition: transform 0.3s ease-in-out;
}

.popupModal .icons a:hover i {
  transform: scale(1.2);
}
.popupModal .content .copyToClipboardArea {
  margin: 12px 0 -5px 0;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}
.popupModal .copyToClipboardArea.active {
  border-color: #7d2ae8;
}
.popupModal .copyToClipboardArea i {
  width: 50px;
  font-size: 18px;
  text-align: center;
}
.popupModal .copyToClipboardArea.active i {
  color: #7d2ae8;
}
.popupModal .copyToClipboardArea input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 15px;
  padding: 0 5px;
}
.popupModal .copyToClipboardArea button {
  color: #fff;
  padding: 5px 18px;
  background: #5287b3;
}
.popupModal .copyToClipboardArea button:hover {
  background: #5287b3;
}
#backdropId .popupModal.show {
  max-width: 65%;
  height: 88%;
   /* max-width: 400px; */
  /* height: auto; */
}

.copiedToClipboard {
  position: fixed;
  z-index: 1;
  top: 0;
  right: 0;
  width: 100%;
  height: 45px;
  padding: 10px;
  background: #dbffdb;
  color: #107010;
  /* border-radius: 15px 15px 0 0; */
  border-top: 1px solid #cccccc;
  border-bottom: 1px solid #cccccc;
}
.copiedToClipboard p svg {
  font-size: 24px;
}

.popup-modal-backdrop {
  background: rgb(94 88 88 / 78%);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  display: none;
}
.popup-modal-backdrop.show {
  display: block;
}

.goog-te-combo {
  color: #374a77 !important;
  font-size: 16px !important;
  line-height: 30px !important;
  cursor: pointer;
  padding: 10px 5px;
  border: 1px solid lightgrey !important;
  width: 100% !important;
  border-radius: 5px;
}
.goog-te-combo:focus,
.goog-te-combo:hover {
  border: 1px solid lightgrey !important;
}

/* end share modal */

/* google translate styles */

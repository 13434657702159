:global(.arabic-lang) {
  .countryCodePhoneInputContainer {
    flex-direction: row-reverse;
  }
}

.countryCodePhoneInputContainer {
  display: flex;
  align-items: center;
  width: 100%;
  flex-grow: 1;
}

.content {
  max-width: max-content !important;
  margin-bottom: 110px;
  overflow-y: unset !important;
}
.header {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 18px;
}
.logo {
}
.titleAndSubtitleContainer {
  text-align: center;
}
.popupTitle {
  text-align: center !important;
  font-size: 18px;
  font-weight: 600;
  color: #273454;
}
.popupSubtitle {
  text-align: center !important;
  font-size: 14px;
  font-weight: 600;
  color: #273454;
}
.formContainer {
  display: flex;
  flex-direction: column;
  gap: 26px;
  flex: 1;

  input {
    border: none;
    border-radius: 0;

    height: 46px;
    border-bottom: 1px solid #273454;
  }
}
.countryCodeLabel {
  white-space: pre;
}
.phoneInputField {
  width: 100px !important;
  height: 46px;
  margin-bottom: 0;
}

.formContainer .phoneInputField :global(.form-control) {
  display: none;
}
.formContainer .phoneInputField :global(.country-list) {
  left: -70px;
}

.formContainer :global(.errorformmessage) {
  margin-top: unset;
  margin-bottom: unset;
}

.phoneInputField :global(.form-control)::placeholder {
  color: transparent !important;
}

.codeInputAndButtonContainer {
  display: flex;
  align-items: center;
  gap: 6px;
}

.codeInputAndButtonContainer div:first-of-type {
  height: 46px;
  flex: 1;
}

.codeInputField {
  flex: 1;
  width: 200px;
}

.sendCodeButton,
.continueButton {
  background-color: #3488cd !important;
  color: #fff !important;
  height: 46px;
}

.sendCodeButton:disabled,
.continueButton:disabled {
  background-color: #d3d3d3 !important;
  color: #fff !important;
}

.sendCodeButton:hover,
.continueButton:hover {
  background-color: #374a77 !important;
  color: #fff !important;
}

.saveAndContinueButton {
}
.resendCode {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 4px;
}
.resendCodeLink {
  all: unset;
  transition: color 0.1s ease-in-out;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &:hover {
    text-decoration: underline;
    color: #5287b3;
  }
}

.footer {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

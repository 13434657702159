.container {
  position: fixed;
  inset: 0;
  width: 100vw;
  height: 100dvh;
  backdrop-filter: blur(0.125rem);
  display: grid;
  place-items: center;
  z-index: 1;

  .content {
    background-color: #fff;
    width: 100%;
    max-width: 400px;
    height: max-content;
    max-height: 80dvh;
    padding: 1rem;
    border-radius: 1rem;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .body {
      display: flex;
      flex-direction: column;
      flex: 1;
    }
    .body * {
      text-align: start;
      unicode-bidi: plaintext;
    }
  }
}
